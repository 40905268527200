import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { TextValidator } from "react-material-ui-form-validator";
// theme
import theme from "./../../theme";

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& > div": {
      width: "100%",
    },
  },
  input: {
    borderRadius: 0,
    backgroundColor: theme.palette.white,
    border: theme.borders[1],
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    height: "38px",
    "& > input": {
      "&:after": {
        "& .MuiInput-underline:after": {
          position: "fixed",
        },
      },
    },
  },
}));

const TextFieldWithLabel = ({ InputProps, label, ...rest }) => {
  const classes = useStyles(theme);
  return (
    <Box>
      <Box mb={1}>
        <Typography style={{ textAlign: "start" }} variant="subtitle1">
          {label}
        </Typography>
      </Box>
      <TextValidator
        InputProps={{
          disableUnderline: true,
          className: `${classes.input}`,
          variant: "standard",
          ...InputProps,
        }}
        {...rest}
      />
    </Box>
  );
};

export default TextFieldWithLabel;
