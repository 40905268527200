import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import MuiPhoneNumber from "material-ui-phone-number";
// theme
import theme from "./../../theme";

const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiButtonBase-root": {
      backgroundColor: theme.palette.grayE5,
    },
    "& .MuiInputBase-root ": {
      borderRadius: 0,
      border: ({ disabled }) => (!disabled ? theme.borders[1] : theme.borders[6]),
      paddingTop: theme.spacing(1 / 2),
      paddingBottom: theme.spacing(1 / 2),
      paddingLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
  },
}));

const TextFieldPhone = ({ value, onChange, label, ...rest }) => {
  const classes = useStyles(theme);
  return (
    <Box>
      <Box mb={1}>
        <Typography style={{ textAlign: "start", color: theme.palette.black }} variant="subtitle1">
          {label}
        </Typography>
      </Box>
      <MuiPhoneNumber
        value={value}
        onChange={onChange}
        className={classes.input}
        disableAreaCodes
        fullWidth
        {...rest}
      />
    </Box>
  );
};

export default TextFieldPhone;
