import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import CheckIcon from "@material-ui/icons/Check";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    position: "relative",
    border: theme.borders[6],
    borderRadius: "5px",
    height: "22px",
    width: "22px",
  },
  checkedBackground: {
    backgroundColor: theme.palette.primary.main,
  },
}));

function CheckBoxCustom() {
  const classes = useStyles(theme);
  return <Box className={classes.box} />;
}

function CheckedBox() {
  const classes = useStyles(theme);
  return (
    <Box className={`${classes.box} `}>
      <CheckIcon style={{ color: theme.palette.primary.main }} />
    </Box>
  );
}

function CustomCheckBox2({ checked = false, onClick = () => {}, ...rest }) {
  return (
    <Checkbox
      onClick={onClick}
      checked={checked}
      icon={<CheckBoxCustom />}
      checkedIcon={<CheckedBox />}
      {...rest}
    />
  );
}

export default CustomCheckBox2;
